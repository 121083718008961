import { createApi } from "@reduxjs/toolkit/query/react";

import { axiosBaseQuery } from "./fetch";
import { serverConfig } from "../../config";
import { Tier, TierFormValues } from "../../types/tier";
import { ApiResponse } from "../../types/response";
import createQueryParams from "../../utils/createQueryParams";

const TAG = "Tiers";
const LIST = "LIST";

export const tiersApi = createApi({
  reducerPath: "tiersApi",
  baseQuery: axiosBaseQuery({
    baseUrl: `${serverConfig.url}/${serverConfig.endpoint_prefix}/${serverConfig.version}/`,
  }),
  tagTypes: [TAG],
  endpoints: (builder) => ({
    getTierById: builder.query<ApiResponse<Tier>, { id: string }>({
      query: ({ id }) => ({
        url: `tiers/${id}`,
        method: "GET",
      }),
      providesTags: (result, err) =>
        result?.data ? [{ type: TAG, id: result.data.id }] : [],
    }),

    getTiers: builder.query<ApiResponse<Tier[]>, { orgId?: string }>({
      query: ({ orgId }) => ({
        url: `tiers${createQueryParams({ orgId })}`,
        method: "GET",
      }),
      providesTags: (result, err) =>
        result?.data != null
          ? [
              { type: TAG, id: LIST },
              ...result.data.map((i) => ({ type: TAG, id: i.id } as const)),
            ]
          : [],
    }),

    createTier: builder.mutation<ApiResponse<Tier>, { data: TierFormValues }>({
      query: ({ data }) => ({
        url: `tiers`,
        method: "POST",
        data: data,
      }),
      invalidatesTags: (result, err) =>
        result?.data ? [{ type: TAG, id: LIST }] : [],
    }),

    updateTier: builder.mutation<
      ApiResponse<Tier>,
      { id: string; data: Partial<Tier> }
    >({
      query: ({ id, data }) => ({
        url: `tiers/${id}`,
        method: "PATCH",
        data: data,
      }),
      invalidatesTags: (result, err) =>
        result?.data
          ? [
              { type: TAG, id: LIST },
              { type: TAG, id: result.data.id },
            ]
          : [],
    }),

    deleteTier: builder.mutation<ApiResponse<Tier>, { id: string }>({
      query: ({ id }) => ({
        url: `tiers/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: (result, err) =>
        result?.data ? [{ type: TAG, id: LIST }] : [],
    }),

    getSharedTiers: builder.query<ApiResponse<Tier[]>, void>({
      query: () => ({
        url: `shared_tiers`,
        method: "GET",
      }),
    }),
  }),
});

export const {
  useGetTierByIdQuery,
  useGetTiersQuery,
  useCreateTierMutation,
  useUpdateTierMutation,
  useDeleteTierMutation,
  useGetSharedTiersQuery,
} = tiersApi;
