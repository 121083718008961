import { createApi } from "@reduxjs/toolkit/query/react";

import { axiosBaseQuery } from "./fetch";
import { serverConfig } from "../../config";
import { ApiResponse } from "../../types/response";
import { ParentType, Setting, SettingFormValues } from "../../types/setting";

const TAG = "Settings";
const LIST = "LIST";

function generateGetQuery(args: {
  parentId?: string;
  parentType?: ParentType;
}) {
  if (args.parentId && args.parentType) {
    return `?parentId=${args.parentId}&parentType=${args.parentType}`;
  } else {
    return ``;
  }
}

export const settingsApi = createApi({
  reducerPath: "settingsApi",
  baseQuery: axiosBaseQuery({
    baseUrl: `${serverConfig.url}/${serverConfig.endpoint_prefix}/${serverConfig.version}/`,
  }),
  tagTypes: [TAG],
  endpoints: (builder) => ({
    getSettings: builder.query<
      ApiResponse<Setting[]>,
      { parentId?: string; parentType?: ParentType }
    >({
      query: ({ parentId, parentType }) => ({
        url: `settings${generateGetQuery({ parentId, parentType })}`,
        method: "GET",
      }),
      providesTags: (result, err) =>
        result?.data != null
          ? [
              { type: TAG, id: LIST },
              ...result.data.map((i) => ({ type: TAG, id: i.id } as const)),
            ]
          : [],
    }),

    createSetting: builder.mutation<
      ApiResponse<Setting>,
      { data: SettingFormValues & { parentId: string; parentType: ParentType } }
    >({
      query: ({ data }) => ({
        url: `settings`,
        method: "POST",
        data: data,
      }),
      invalidatesTags: (result, err) =>
        result?.data ? [{ type: TAG, id: LIST }] : [],
    }),

    updateSetting: builder.mutation<
      ApiResponse<Setting>,
      { id: string; data: Partial<Setting> }
    >({
      query: ({ id, data }) => ({
        url: `settings/${id}`,
        method: "PATCH",
        data: data,
      }),
      invalidatesTags: (result, err) =>
        result?.data
          ? [
              { type: TAG, id: result.data.id },
              { type: TAG, id: LIST },
            ]
          : [],
    }),

    deleteSetting: builder.mutation<ApiResponse<Setting>, { id: string }>({
      query: ({ id }) => ({
        url: `settings/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: (result, err) =>
        result?.data ? [{ type: TAG, id: LIST }] : [],
    }),
  }),
});

export const {
  useGetSettingsQuery,
  useCreateSettingMutation,
  useUpdateSettingMutation,
  useDeleteSettingMutation,
} = settingsApi;
