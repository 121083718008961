import { useEffect, useState } from "react";
import type { FC, ReactNode } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { experimentalStyled } from "@mui/material/styles";
// import Footer from "./Footer";
import MainNavbar from "./MainNavbar";
import MainSidebar from "./MainSidebar";
import isOnShareSubdomain from "../utils/isOnShareSubdomain";

const MainLayoutRoot = experimentalStyled("div")(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  minHeight: "100vh",
  paddingTop: 64,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
}));

const MainLayout: FC<{
  children?: ReactNode;
}> = ({ children }) => {
  const navigate = useNavigate();

  const [isSidebarMobileOpen, setIsSidebarMobileOpen] =
    useState<boolean>(false);

  const isShareSubdomain = isOnShareSubdomain();
  useEffect(() => {
    if (isShareSubdomain) {
      navigate(`/forbidden`);
    }
  }, [isShareSubdomain, navigate]);

  return (
    <MainLayoutRoot>
      <MainNavbar
        onSidebarMobileOpen={(): void => setIsSidebarMobileOpen(true)}
      />
      <MainSidebar
        onMobileClose={(): void => setIsSidebarMobileOpen(false)}
        openMobile={isSidebarMobileOpen}
      />
      {children || <Outlet />}
      {/* <Footer /> */}
    </MainLayoutRoot>
  );
};

export default MainLayout;
