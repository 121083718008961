const shareRegex = /^share.(.*)illumix.com/;

const isOnShareSubdomain = (wndw: Window = window): boolean => {
  return (
    wndw.location.host.match(shareRegex) != null ||
    wndw.location.hostname.match(shareRegex) != null
  );
};

export default isOnShareSubdomain;
