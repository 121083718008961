import { useEffect } from "react";
import type { FC } from "react";
import { Link as RouterLink, useLocation } from "react-router-dom";
import { Box, Button, Typography, Drawer, Hidden } from "@mui/material";
import Logo from "./common/Logo";

interface MainSidebarProps {
  onMobileClose: () => void;
  openMobile: boolean;
}

const MainSidebar: FC<MainSidebarProps> = (props) => {
  const { onMobileClose, openMobile } = props;
  const location = useLocation();

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location.pathname]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Hidden lgUp>
      <Drawer
        anchor="left"
        onClose={onMobileClose}
        open={openMobile}
        variant="temporary"
        PaperProps={{
          sx: {
            backgroundColor: "background.default",
            width: 256,
          },
        }}
      >
        <Box
          sx={{
            alignItems: "flex-start",
            display: "flex",
            flexDirection: "column",
            height: "100%",
            p: 2,
          }}
        >
          <RouterLink to="/">
            <Logo name sx={{ width: "100%", height: "100%", py: 1, px: 3 }} />
          </RouterLink>
          <Box sx={{ p: 2, mt: 5 }}>
            <Typography color="textPrimary" variant="subtitle2">
              Need Help?
            </Typography>
            <Typography color="textSecondary" variant="body2">
              Contact our support
            </Typography>
            <Button
              color="primary"
              component={RouterLink}
              fullWidth
              sx={{ mt: 2 }}
              to="/contact"
              variant="outlined"
            >
              Contact
            </Button>
          </Box>
        </Box>
      </Drawer>
    </Hidden>
  );
};

export default MainSidebar;
