import { Box } from "@mui/material";
import { SxProps } from "@mui/system";
import Avatar from "boring-avatars";
import { FC } from "react";

const BoringAvatar: FC<{ name: string; size: number; sx?: SxProps }> = (
  props
) => {
  return (
    <Box sx={props.sx}>
      <Avatar
        size={props.size}
        name={props.name}
        variant="beam"
        colors={["#92A1C6", "#146A7C", "#F0AB3D", "#C271B4", "#C20D90"]}
      />
    </Box>
  );
};

export default BoringAvatar;
