import {PropsWithChildren} from "react";
import type { FC } from "react";
import {
  Box,
  Dialog, Typography,
} from "@mui/material";
import { SxProps } from "@mui/system";
import { ModalId } from "../../hooks/contexts/ModalContext";
import Logger from "../../lib/logger";
import useModal from "../../hooks/useModal";

export type ModalProps = {
  modalKey: ModalId;
  maxWidth: "xs" | "sm" | "md" | "lg" | "xl";
  text?: string;
  className?: string;
  sx?: SxProps;
} & PropsWithChildren

const Modal: FC<ModalProps> = (props) => {
  Logger.debug({ prefix: `Modal:${props.modalKey}`, msg: props });

  const { modal, toggleModal } = useModal();

  return (
      <Dialog
        key={props.modalKey}
        maxWidth={props.maxWidth || "lg"}
        onClose={() => toggleModal(modal ? undefined : props.modalKey)}
        open={modal === props.modalKey}
        sx={props.sx || {}}
      >
        <Box sx={{p: 3}}>
          {props.text && <Typography
            marginBottom={3}
            align="center"
            color="text.primary"
            variant="h6"
            gutterBottom
          >
            {props.text}
          </Typography>}

          {props.children}
        </Box>
      </Dialog>
  );
};

export default Modal;
