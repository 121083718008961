import {FC, PropsWithChildren, useEffect} from "react";
import useAuth from "../hooks/useAuth";
import {useNavigate} from "react-router-dom";
import {useGetUserByIdQuery} from "../store/services/users";
import {ApiResponseError} from "../types/response";
import SplashScreen from "./SplashScreen";
import usePermissions from "../hooks/usePermissions";

/*
* Checks if user is valid, and if so initiates user permissions for usePermissions hook
* */
const UserFetchWrapper:FC<PropsWithChildren> = (props) => {
  const { userId } = useAuth();
  const navigate = useNavigate();
  const {
    initiatePermissions,
    state: { ids },
  } = usePermissions();
  
  const { data: user, error: userErr } = useGetUserByIdQuery({
    id: userId || '',
    include: ["permissions"],
  });
  
  useEffect(() => {
    // handle user api errors
    if (userErr != null) {
      let err = userErr as ApiResponseError;
      navigate(
        `/${err.status && typeof err.status === "number" ? err.status : 500}`
      );
    }
    
    // init permissions
    if (ids == null && user?.data != null && user?.meta?.permissions != null) {
      initiatePermissions({
        ids: {
          user: user.data.id,
          org: user.data.orgId,
        },
        permissionsMap: user.meta.permissions,
      });
    }
  }, [navigate, userErr, ids, user, initiatePermissions]);
  
  return (
    <>
      {user?.data ? <>{props.children}</> : <SplashScreen /> }
    </>
  );
};

export default UserFetchWrapper;