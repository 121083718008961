import { createApi } from "@reduxjs/toolkit/query/react";

import { axiosBaseQuery } from "./fetch";
import { serverConfig } from "../../config";
import { ApiResponse } from "../../types/response";
import { Permission } from "../../types/permissions";

const TAG = "Permission";
const LIST = "LIST";

export const permissionsApi = createApi({
  reducerPath: "permissionsApi",
  baseQuery: axiosBaseQuery({
    baseUrl: `${serverConfig.url}/${serverConfig.endpoint_prefix}/${serverConfig.version}/`,
  }),
  tagTypes: [TAG],
  endpoints: (builder) => ({
    getPermissionsById: builder.query<ApiResponse<Permission>, { id: string }>({
      query: ({ id }) => ({ url: `permissions/${id}`, method: "GET" }),
      providesTags: (result, err) =>
        result?.data ? [{ type: TAG, id: result.data.id }] : [],
    }),

    getPermissions: builder.query<
      ApiResponse<Permission[]>,
      { userId: string }
    >({
      query: ({ userId }) => ({
        url: `permissions?userId=${userId}`,
        method: "GET",
      }),
      providesTags: (result, err) =>
        result?.data != null
          ? [
              { type: TAG, id: LIST },
              ...result.data.map((i) => ({ type: TAG, id: i.id } as const)),
            ]
          : [],
    }),

    createPermission: builder.mutation<
      ApiResponse<Permission>,
      { data: Omit<Permission, "id" | "createdAt" | "updatedAt"> }
    >({
      query: ({ data }) => ({
        url: `permissions`,
        method: "POST",
        data: data,
      }),
      invalidatesTags: (result, err) =>
        result?.data ? [{ type: TAG, id: LIST }] : [],
    }),

    updatePermission: builder.mutation<
      ApiResponse<Permission>,
      { id: string; data: Partial<Permission> }
    >({
      query: ({ id, data }) => ({
        url: `permissions/${id}`,
        method: "PUT",
        data: data,
      }),
      invalidatesTags: (result, err) =>
        result?.data ? [{ type: TAG, id: LIST }] : [],
    }),
  }),
});

export const {
  useGetPermissionsByIdQuery,
  useGetPermissionsQuery,
  useCreatePermissionMutation,
  useUpdatePermissionMutation,
} = permissionsApi;
