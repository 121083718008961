import {FC, ReactNode, useEffect} from "react";
import {useNavigate} from "react-router-dom";
import {URIConfig} from "../config";
import useAuth from "../hooks/useAuth";
import UserFetchWrapper from "./UserFetchWrapper";

/*
* This component verifies that the Auth token exists, is valid AND the user exists in our system
*  then redirects based on that
* */

const AuthGuard: FC<{ children: ReactNode }> = (props) => {
  const navigate = useNavigate();
  const { isAuthenticated } = useAuth();
  
  useEffect(() => {
    if (!isAuthenticated) {
      const encodedCallbackUrl = encodeURI(window.location.href);
      window.location.assign(`${URIConfig.org_uri}/authenticate?callbackUrl=${encodedCallbackUrl}`);
    }
  }, [isAuthenticated, navigate]);
  
  return <UserFetchWrapper>{props.children}</UserFetchWrapper>;
};

export default AuthGuard;
