import { ReactNode } from "react";
import type { FC } from "react";
import {
  Button,
  IconButton,
  Tooltip,
} from "@mui/material";
import { SxProps } from "@mui/system";
import { Mouse as MouseIcon } from "@mui/icons-material";
import useModal from "../../../hooks/useModal";
import { ModalId } from "../../../hooks/contexts/ModalContext";
import Logger from "../../../lib/logger";
import Modal from "../Modal";

type TextButtonProps = {
  sx?: SxProps;
  className?: string;
  fullWidth?: boolean;
  disabled?: boolean;

  text: string;
  variant?: "text" | "outlined" | "contained";
  startIcon?: ReactNode;
  size?: "small" | "medium" | "large";
  color?:
    | "inherit"
    | "primary"
    | "secondary"
    | "error"
    | "info"
    | "success"
    | "warning";
};

const TextModalButton: FC<TextButtonProps & { onClick: () => void }> = (
  props
) => {
  return (
    <Button
      sx={props.sx}
      className={props.className || ""}
      fullWidth={props.fullWidth}
      onClick={props.onClick}
      color={props.color}
      variant={props.variant}
      startIcon={props.startIcon}
      size={props.size}
      disabled={props.disabled}
    >
      {props.text}
    </Button>
  );
};

type IconButtonProps = {
  sx?: SxProps;
  className?: string;
  icon: ReactNode;
  disabled?: boolean;
  color?:
    | "inherit"
    | "default"
    | "primary"
    | "secondary"
    | "error"
    | "info"
    | "success"
    | "warning";
};

const IconModalButton: FC<IconButtonProps & { onClick: () => void }> = (
  props
) => {
  return (
    <IconButton
      sx={props.sx}
      className={props.className || ""}
      color={props.color}
      onClick={props.onClick}
      disabled={props.disabled}
    >
      {props.icon || <MouseIcon fontSize="small" />}
    </IconButton>
  );
};

const ModalButton: FC<{
  modalKey: ModalId;
  modalMaxWidth: "xs" | "sm" | "md" | "lg" | "xl";
  modalText: string;
  tooltip?: string;
  textButton?: TextButtonProps;
  iconButton?: IconButtonProps;
  className?: string;
  children?: ReactNode;
}> = (props) => {
  Logger.debug({ prefix: `ModalButton:${props.modalKey}`, msg: props });

  const { modal, toggleModal } = useModal();

  function getButtons() {
    return (
      <>
        {props.textButton && (
          <TextModalButton
            onClick={() => toggleModal(modal ? undefined : props.modalKey)}
            className={props.className}
            {...props.textButton}
          />
        )}

        {props.iconButton && (
          <IconModalButton
            onClick={() => toggleModal(modal ? undefined : props.modalKey)}
            className={props.className}
            {...props.iconButton}
          />
        )}
      </>
    );
  }

  return (
    <>
      {props.tooltip ? (
        <Tooltip title={props.tooltip}>{getButtons()}</Tooltip>
      ) : (
        getButtons()
      )}

      <Modal
        modalKey={props.modalKey}
        maxWidth={props.modalMaxWidth || "lg"}
        text={props.modalText}
      >
        {props.children}
      </Modal>
    </>
  );
};

export default ModalButton;
