import { createContext, useState } from "react";
import type { FC, ReactNode } from "react";

export type ModalId = string; // "org:settings:create";

export interface ModalContextValue {
  modal: ModalId | null;
  toggleModal: (modal?: ModalId) => void;
}

const ModalContext = createContext<ModalContextValue>({
  modal: null,
  toggleModal: () => {},
});

export const ModalProvider: FC<{
  children?: ReactNode;
}> = (props) => {
  const { children } = props;
  const [modal, setModal] = useState<ModalId | null>(null);

  const toggleModal = (modal?: ModalId): void => {
    setModal(modal ? modal : null);
  };

  return (
    <ModalContext.Provider
      value={{
        modal,
        toggleModal,
      }}
    >
      {children}
    </ModalContext.Provider>
  );
};

export const ModalConsumer = ModalContext.Consumer;

export default ModalContext;
