import React, {FC, ReactNode, useMemo, useState} from "react";
import {Outlet, useParams} from "react-router-dom";
import { experimentalStyled } from "@mui/material/styles";

import { useGetUserByIdQuery } from "../../store/services/users";
import DashboardNavbarV2 from "./DashboardNavbarV2";
import {Box, Hidden} from "@mui/material";
import useAuth from "../../hooks/useAuth";
import {envConfig} from "../../config";
import {AdminBanner, DashboardSidebar} from "illumix-dashboard-shared-components";
import Logo from "../common/Logo";
import {useGetOrgByIdQuery, useGetOrgsQuery} from "../../store/services/orgs";
import {Project, ProjectType} from "../../types/project";
import usePermissions from "../../hooks/usePermissions";
import {useGetProjectsQuery} from "../../store/services/projects";
import {actions, RootState, useDispatch, useSelector} from "../../store";

const DashboardLayoutRoot = experimentalStyled("div")(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  display: "flex",
  flexGrow: 1,
  flexDirection: 'column',
  height: "100%",
  overflow: "hidden",
  width: "100%",
}));

const DashboardLayoutWrapper = experimentalStyled("div")(({ theme }) => ({
  display: "flex",
  flex: "1 1 auto",
  overflow: "hidden",
}));

const DashboardLayoutContainer = experimentalStyled("div")({
  display: "flex",
  flex: "1 1 auto",
  overflow: "hidden",
});

const DashboardLayoutContent = experimentalStyled("div")({
  flex: "1 1 auto",
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'nowrap',
  height: "100%",
  overflow: "auto",
  position: "relative",
  WebkitOverflowScrolling: "touch",
});

const DASHBOARD_WIDTH = '280px';

const venueV2Type: ProjectType = 'venue_v2';
const venueType: ProjectType = 'venue';
const tryonType: ProjectType = 'try_on';

const DashboardLayoutV2: FC<{ children?: ReactNode }> = (props) => {
  const { userId } = useAuth();
  const { paramOrgId, paramProjectId } = useParams();
  const { data } = useGetUserByIdQuery({
    id: userId || '',
    include: ["permissions"],
  });
  const { data: orgData } = useGetOrgByIdQuery({
    id: paramOrgId || '',
    include: ["tier", "settings"],
  });
  const { data: projectsData } = useGetProjectsQuery({
    orgId: paramOrgId,
  });
  const { data: allOrgsData } = useGetOrgsQuery();
  
  const permissions = usePermissions();
  const dispatch = useDispatch();
  
  const hasTryOn = useMemo(() => {
    return orgData?.data?.projectOptions?.some((option) => option.startsWith(tryonType));
  }, [orgData])
  const hasVenueV1 = useMemo(() => {
    return orgData?.data?.projectOptions?.includes(venueType);
  }, [orgData])
  const hasVenueV2 = useMemo(() => {
    return orgData?.data?.projectOptions?.includes(venueV2Type);
  }, [orgData])
  const hasViewOrg = permissions.hasPermission({
    action: "view",
    resource: "orgs",
  });
  const hasViewUsers = permissions.hasPermission({
    action: "view",
    resource: "users",
    orgId: paramOrgId,
  });
  const hasViewAPIKeys = permissions.hasPermission({
    action: "view",
    resource: "apikeys",
    orgId: paramOrgId,
  });
  
  const [isSidebarMobileOpen, setIsSidebarMobileOpen] =
    useState<boolean>(true);
  
  const currentProject: Project | undefined = useSelector((state:RootState) => state.projects.currentProject);

  const isAdmin = data?.data?.role === "admin";
  const isInAdminDashboard = window.location.pathname.includes("admin");
  const showAdminBanner = isAdmin && !isInAdminDashboard;

  return (
    <>
      <DashboardLayoutRoot>
        {userId && data?.data && (
          <>
            {showAdminBanner && <AdminBanner orgs={allOrgsData?.data || []} isLocal={envConfig.env === 'development'}/>}
            <DashboardNavbarV2 adminBannerDisplayed={showAdminBanner} setIsSidebarMobileOpen={setIsSidebarMobileOpen} user={data.data} orgId={paramOrgId} />
            <DashboardSidebar
              adminBannerDisplayed={showAdminBanner}
              isAdmin={data?.data?.role === "admin"}
              isLocal={envConfig.env === 'development'}
              logo={<Logo
                name={true}
                sx={{
                  height: 20,
                  width: "inherit",
                }}
              />}
              orgId={paramOrgId || ''}
              user={data.data}
              width={DASHBOARD_WIDTH}
              onMobileClose={(): void => setIsSidebarMobileOpen(false)}
              openMobile={isSidebarMobileOpen}
              permissions={{
                hasVenueV1,
                hasVenueV2,
                hasTryOn,
                hasViewOrg,
                hasViewUsers,
                hasViewAPIKeys,
              }}
              availableProjects={projectsData?.data || []}
              selectedProject={currentProject}
              // @ts-ignore
              setSelectedProject={(project: Project | undefined & void) => dispatch(actions.setCurrentProject(project))}
            />
            <DashboardLayoutWrapper>
              <DashboardLayoutContainer>
                <DashboardLayoutContent>
                  <Hidden lgDown>
                    <Box sx={{minWidth: DASHBOARD_WIDTH}}></Box>
                  </Hidden>
                  <Box sx={{flexGrow: 1, paddingTop: `${isAdmin ? 80 : 40}px`}}>
                    {/* different key per project forces an refresh for the outlet but NOT the entire app when new project is selected */}
                    {props.children || <Outlet key={paramProjectId} />}
                  </Box>
                </DashboardLayoutContent>
              </DashboardLayoutContainer>
            </DashboardLayoutWrapper>
          </>
        )}
      </DashboardLayoutRoot>
    </>
  );
};

export default DashboardLayoutV2;
