import { createContext, ReactNode, useState } from "react";
import type { FC } from "react";
import Logger from "../../lib/logger";

const ILLUMIX_ORG_ID = "1";

export type PermissionState = {
  ids?: {
    org: string;
    user: string;
  };
  isIllumixStaff: boolean;
  permissions: Record<string, string[]>;
};

export interface PermissionsContextValue {
  state: PermissionState;
  initiatePermissions: ({
    ids,
    permissionsMap,
  }: {
    ids: {
      org: string;
      user: string;
    };
    permissionsMap: Record<string, string[]>;
  }) => void;
  hasPermission: ({
    action,
    resource,
    resourceId,
    orgId,
  }: HasPermissionsArgs) => boolean;
}

export type HasPermissionsArgs = {
  action: "view" | "create" | "update" | "delete";
  resource:
    | "orgs"
    | "users"
    | "projects"
    | "items"
    | "assets"
    | "apikeys"
    | "permissions"
    | "locations"
    | "environments"
    | "sharedanchors"
    | "occlusionmeshes"
    | "tryonsharedlinks"
    | "hyperionwebbuilds"
    | "settings"
    | "tiers"
    | "sharedTiers"
    | "paymenthistories"
    | "experiences"
    | "zones"
    | "spatialcaptures"
    | "schedules"
    | "activators";
  resourceId?: string;
  orgId?: string;
};

const PermissionsContext = createContext<PermissionsContextValue>({
  state: {
    isIllumixStaff: false,
    permissions: {},
  },
  initiatePermissions: ({
    ids,
    permissionsMap,
  }: {
    ids: {
      org: string;
      user: string;
    };
    permissionsMap: Record<string, string[]>;
  }) => {},
  hasPermission: ({
    action,
    resource,
    resourceId,
    orgId,
  }: HasPermissionsArgs) => false,
});

export const PermissionsProvider: FC<{ children?: ReactNode }> = (props) => {
  const [state, setState] = useState<PermissionState>({
    isIllumixStaff: false,
    permissions: {},
  });

  function hasPermission({
    action,
    resource,
    resourceId,
    orgId,
  }: HasPermissionsArgs) {
    if (state.ids == null) {
      Logger.error({
        prefix: "PermissionsProvider",
        msg: `Missing permission state -- action: ${action}, resource: ${resource}, resourceId: ${resourceId}, orgId: ${orgId},`,
      });
      return true;
    }

    const permissionKey = `${action}${resource
      .charAt(0)
      .toUpperCase()}${resource.slice(1)}`;
    const permissionValues = resourceId ? ["*", resourceId] : ["*"];
    const permissionInOrgsKey = `${permissionKey}InOrgs`;
    const permissionInOrgsValues = orgId ? ["*", orgId] : ["*"];

    // check permission on target resource
    if (
      state.permissions[permissionKey]?.some((p) =>
        permissionValues.includes(p)
      )
    ) {
      return true;
    }

    // check permission on target resource in org
    if (
      state.permissions[permissionInOrgsKey]?.some((p) =>
        permissionInOrgsValues.includes(p)
      )
    ) {
      return true;
    }

    return false;
  }

  function initiatePermissions({
    ids,
    permissionsMap,
  }: {
    ids: {
      org: string;
      user: string;
    };
    permissionsMap: Record<string, string[]>;
  }) {
    Logger.debug({
      prefix: `initiatePermissions.userId=${ids.user}.orgId=${ids.org}`,
      msg: permissionsMap,
      pretty: true,
    });

    if (state.ids == null) {
      setState((p) => ({
        ids: ids,
        isIllumixStaff: ids.org === ILLUMIX_ORG_ID,
        permissions: { ...p.permissions, ...permissionsMap },
      }));
    }
  }

  return (
    <PermissionsContext.Provider
      value={{
        state,
        hasPermission,
        initiatePermissions,
      }}
    >
      {props.children}
    </PermissionsContext.Provider>
  );
};

export const SettingsConsumer = PermissionsContext.Consumer;

export default PermissionsContext;
