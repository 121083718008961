import { createContext, useEffect, useState } from "react";
import type { FC, ReactNode } from "react";

import Tours from "../../shepherd/tours";
import Logger from "../../lib/logger";
import {
  Step,
  stepAnchorElementExists,
} from "../../shepherd/tours/common/steps";

import {
  createShepherdTour,
  getTourConfig,
  saveTourConfig,
} from "../../shepherd/tours/common/shepherd";
import { BACK_BUTTON, NEXT_BUTTON } from "../../shepherd/tours/common/buttons";
import usePermissions from "../usePermissions";
import { envConfig } from "../../config";

let tour: ReturnType<typeof createShepherdTour> | null = null;
let overlappingTour: ReturnType<typeof createShepherdTour> | null = null;

export type TourId =
  | "orgPage"
  | "orgListPage"
  | "usersListPage"
  | "projectPage"
  | "productPage";

export interface TourContextValue {
  tour: ReturnType<typeof createShepherdTour> | null;
  addAvailableStep: (step: Step) => void;
  toggleTour: (Tour?: TourId) => void;
}

const TourContext = createContext<TourContextValue>({
  tour: null,
  addAvailableStep: () => [],
  toggleTour: () => {},
});

const DEBUG = false;

export const TourProvider: FC<{
  children?: ReactNode;
}> = ({ children }) => {
  const {
    hasPermission,
    state: { isIllumixStaff },
  } = usePermissions();

  const [tourId, setTourId] = useState<TourId | null>(null);
  const [steps, setSteps] = useState<string[]>([]);

  const addAvailableStep = (step: Step) => {
    if (!new Set(steps).has(step.id)) {
      setSteps((s) => [...s, step.id]);
    }
  };

  const toggleTourId = (newTourId?: TourId) => {
    if (tourId !== newTourId) {
      setSteps([]);
      setTourId(newTourId || null);
    }
  };

  const generateTour = ({ tourKey }: { tourKey: string }) => {
    const tourConfig = getTourConfig();

    tour = createShepherdTour({ tourName: tourKey });

    tour.on("show", (event: { step: Step }) => {
      DEBUG && Logger.debug({ prefix: "tour.on.show", msg: event });

      saveTourConfig({
        tourConfig,
        tourKey,
        stepId: event.step.id,
      });
    });

    tour.on("complete", (event: { step: Step }) => {
      DEBUG && Logger.debug({ prefix: "tour.on.complete", msg: event });

      saveTourConfig({
        tourConfig,
        tourKey,
        stepId: "FINISHED",
      });
      setSteps([]);
    });

    tour.on("close", (event: { step: Step }) => {
      DEBUG && Logger.debug({ prefix: "tour.on.close", msg: event });

      setSteps([]);
    });

    tour.on("cancel", (event: { step: Step }) => {
      DEBUG && Logger.debug({ prefix: "tour.on.cancel", msg: event });

      saveTourConfig({
        tourConfig,
        tourKey,
        stepId: "FINISHED",
      });
      setSteps([]);
    });

    return tour;
  };

  const parseForAvailableSteps = ({
    tour,
    availableStepIds,
    tourSteps,
  }: {
    tour: ReturnType<typeof createShepherdTour> | null;
    availableStepIds: string[];
    tourSteps: Step[];
  }) => {
    if (tour) {
      return tourSteps
        .filter(
          (step) =>
            (step.attachTo == null || availableStepIds.includes(step.id)) &&
            (step.attachTo == null ||
              (step.attachTo != null && stepAnchorElementExists(step)))
        )
        .map((step, indx) => {
          const buttons =
            indx === 0
              ? [NEXT_BUTTON({ tour })]
              : [BACK_BUTTON({ tour }), NEXT_BUTTON({ tour })];

          return { ...step, buttons };
        });
    }

    return [];
  };

  useEffect(() => {
    DEBUG &&
      Logger.debug({
        prefix: "TourContext.useEffect",
        msg: { tourId, steps, hasPermission, isIllumixStaff, tour },
      });

    if (envConfig.backend_env === "sandbox" && tourId && steps.length > 0) {
      const tourKey = Tours[tourId].key;

      const tourConfig = getTourConfig();
      const tourConfigState = tourConfig[tourKey];

      DEBUG &&
        Logger.debug({
          prefix: "TourContext.useEffect.{tourConfig,tour,currentStepId}",
          msg: { tourConfig, tour },
        });

      if (tourConfigState !== "FINISHED") {
        let currentStepId = tourConfigState;

        if (tour != null && tour.isActive()) {
          currentStepId = tour.getCurrentStep()?.id;
          overlappingTour = tour;
        }

        tour = generateTour({ tourKey });

        const tourSteps = Tours[tourId]
          .getSteps({
            hasPermission,
            isIllumixStaff,
          })
          .map((s) => s.step);

        DEBUG &&
          Logger.debug({
            prefix: "TourContext.useEffect.tourSteps",
            msg: tourSteps,
          });

        const parsedSteps = parseForAvailableSteps({
          tour,
          availableStepIds: [...steps],
          tourSteps,
        });

        DEBUG &&
          Logger.debug({
            prefix: "TourContext.useEffect.parsedSteps",
            msg: parsedSteps,
          });

        if (parsedSteps.length > 0) {
          tour.addSteps(parsedSteps);

          if (currentStepId) {
            tour.show(currentStepId);
          } else {
            tour.start();
          }
        }

        if (overlappingTour) {
          overlappingTour.hide();
          overlappingTour = null;
        }
      }
    }
  }, [tourId, steps, hasPermission, isIllumixStaff]);

  return (
    <TourContext.Provider
      value={{
        tour,
        addAvailableStep,
        toggleTour: toggleTourId,
      }}
    >
      {children}
    </TourContext.Provider>
  );
};

export const TourConsumer = TourContext.Consumer;

export default TourContext;
