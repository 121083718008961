const createQueryParams = (queries?: object): string => {
  return queries && Object.values(queries).length > 0
    ? `?${Object.entries(queries)
        .filter(([key, value]) => value !== undefined)
        .map(([key, value]) => `${key}=${value}`)
        .join("&")}`
    : "";
};

export default createQueryParams;
