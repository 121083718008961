import { HasPermissionsArgs } from "../../hooks/contexts/PermissionsContext";
import { createShepherdTour } from "./common/shepherd";
import { createStepObject } from "./common/steps";

export const TOUR_CONFIG_KEY = "usersListPage";

export const tour = createShepherdTour({ tourName: TOUR_CONFIG_KEY });

export const USER_LIST_STEP = createStepObject({
  key: "user-list",
  title: "Organization Users",
  text: ["View all the invited members and their roles."],
});

export const USER_CREATE_STEP = createStepObject({
  key: "user-create",
  title: "Invite User(s)",
  text: ["Invite a user and select their role."],
});

export const USER_REINVITE_STEP = createStepObject({
  key: "user-reinvite",
  title: "Re-invite User(s)",
  text: ["You can reinvite a user and have them recreate a new password."],
});

export function getSteps({
  hasPermission,
  isIllumixStaff,
  orgId,
  projectId,
  productId,
}: {
  hasPermission: (args: HasPermissionsArgs) => boolean;
  isIllumixStaff: boolean;
  orgId?: string;
  projectId?: string;
  productId?: string;
}) {
  const ALL_STEPS = [
    {
      step: USER_LIST_STEP,
      permission: hasPermission({ action: "view", resource: "users", orgId }),
    },
    {
      step: USER_CREATE_STEP,
      permission: hasPermission({ action: "create", resource: "users", orgId }),
    },
    {
      step: USER_REINVITE_STEP,
      permission: hasPermission({ action: "create", resource: "users", orgId }),
    },
  ];
  return ALL_STEPS;
}

const defaultExport = {
  key: TOUR_CONFIG_KEY,
  getSteps,
  tour,
};

export default defaultExport;
