import { HasPermissionsArgs } from "../../hooks/contexts/PermissionsContext";
import { createShepherdTour } from "./common/shepherd";
import { createStepObject } from "./common/steps";

export const TOUR_CONFIG_KEY = "projectPage";

export const tour = createShepherdTour({ tourName: TOUR_CONFIG_KEY });

export const API_CREDENTIALS_STEP = createStepObject({
  key: "api-credentials",
  title: "API Credentials",
  text: ["View your project's SDK credentials here."],
});

export const MANAGE_FILES_STEP = createStepObject({
  key: "manage-files",
  title: "Manage Files",
  text: [
    "Upload generic assets to use throughout the project -- model editing, thumbnails, etc.",
  ],
});

export const PRODUCT_LIST_STEP = createStepObject({
  key: "product-list",
  title: "Products",
  text: ["All created products in this project are shown here."],
});

export function getSteps({
  hasPermission,
  isIllumixStaff,
  orgId,
  projectId,
  productId,
}: {
  hasPermission: (args: HasPermissionsArgs) => boolean;
  isIllumixStaff: boolean;
  orgId?: string;
  projectId?: string;
  productId?: string;
}) {
  const ALL_STEPS = [
    {
      step: API_CREDENTIALS_STEP,
    },
    {
      step: MANAGE_FILES_STEP,
      permission: hasPermission({
        action: "create",
        resource: "assets",
        resourceId: projectId,
        orgId,
      }),
    },
    {
      step: PRODUCT_LIST_STEP,
    },
  ];
  return ALL_STEPS;
}

const defaultExport = {
  key: TOUR_CONFIG_KEY,
  getSteps,
  tour,
};

export default defaultExport;
