import { createApi } from "@reduxjs/toolkit/query/react";

import { axiosBaseQuery } from "./fetch";
import { serverConfig } from "../../config";
import { ApiResponse } from "../../types/response";
import {
  Asset,
  GetAssetsRequest,
  GetAssetsResponse,
  PresignedUrlRequest,
  UrlPresignedResponse,
} from "../../types/asset";
import createQueryParams from "../../utils/createQueryParams";

const TAG = "Assets";
const LIST = "LIST";

export const assetsApi = createApi({
  reducerPath: "assetsApi",
  baseQuery: axiosBaseQuery({
    baseUrl: `${serverConfig.url}/${serverConfig.endpoint_prefix}/${serverConfig.version}/`,
  }),
  tagTypes: [TAG],
  endpoints: (builder) => ({
    getAssets: builder.query<
      ApiResponse<GetAssetsResponse[]>,
      GetAssetsRequest
    >({
      query: ({ queries }) => ({
        url: `assets${createQueryParams(queries)}`,
        method: "GET",
      }),
      providesTags: (result, err) =>
        result?.data != null
          ? [
              { type: TAG, id: LIST },
              ...result.data.map((i) => ({ type: TAG, id: i.id } as const)),
            ]
          : [],
    }),

    createAsset: builder.mutation<
      ApiResponse<string>,
      { queries: { projectId: string; userId: string }; data: FormData }
    >({
      query: ({ queries, data }) => ({
        url: `assets${createQueryParams(queries)}`,
        method: "POST",
        data: data,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }),
      invalidatesTags: (result, err) =>
        result?.data ? [{ type: TAG, id: LIST }] : [],
    }),

    updateAsset: builder.mutation<
      ApiResponse<Asset>,
      { id: string; data: Partial<Asset> }
    >({
      query: ({ id, data }) => ({
        url: `assets/${id}`,
        method: "PUT",
        data: data,
      }),
      invalidatesTags: (result, err) =>
        result?.data
          ? [
              { type: TAG, id: result.data.id },
              { type: TAG, id: LIST },
            ]
          : [],
    }),

    deleteAsset: builder.mutation<ApiResponse<Asset>, { id: string }>({
      query: ({ id }) => ({
        url: `assets/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: (result, err) =>
        result?.data ? [{ type: TAG, id: LIST }] : [],
    }),

    getPresignedURL: builder.mutation<
      ApiResponse<UrlPresignedResponse>,
      {
        data: PresignedUrlRequest;
      }
    >({
      query: ({ data }) => ({
        url: `assets/signed_upload_url`,
        method: "POST",
        data: data,
      }),
      invalidatesTags: (result, err) => [],
    }),
  }),
});

export const {
  useGetAssetsQuery,
  useCreateAssetMutation,
  useUpdateAssetMutation,
  useDeleteAssetMutation,
  useGetPresignedURLMutation,
} = assetsApi;
