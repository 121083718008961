import { createApi } from "@reduxjs/toolkit/query/react";
import { axiosBaseQuery } from "./fetch";
import { serverConfig } from "../../config";
import { ApiResponse } from "../../types/response";

import { BillingLinkRequest, BillingLinkResponse } from "../../types/billing";

const TAG = "Billing";
const LIST = "LIST";

export const billingApi = createApi({
  reducerPath: "billingApi",
  baseQuery: axiosBaseQuery({
    baseUrl: `${serverConfig.url}/${serverConfig.endpoint_prefix}/${serverConfig.version}/`,
  }),
  tagTypes: [TAG],
  endpoints: (builder) => ({
    createBillingLink: builder.mutation<
      ApiResponse<BillingLinkResponse>,
      BillingLinkRequest
    >({
      query: ({ orgId, returnUrl }) => ({
        url: `amberflo/portal_sessions`,
        method: "POST",
        data: { orgId, returnUrl },
      }),
      invalidatesTags: (result, err) =>
        result?.data ? [{ type: TAG, id: LIST }] : [],
    }),
  }),
});

export const { useCreateBillingLinkMutation } = billingApi;
