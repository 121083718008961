import { ApiResponseError } from "../types/response";
import { envConfig } from "../config";

function debug({
  prefix,
  msg,
  pretty = false,
}: {
  prefix: string;
  msg: string | object;
  pretty?: boolean;
}) {
  let message = typeof msg === "string" && pretty ? JSON.parse(msg) : msg;

  if (envConfig.backend_env !== "prod") {
    console.info(`${prefix}: `, message);
  }
}

function warn({
  prefix,
  msg,
  pretty = false,
}: {
  prefix: string;
  msg: string | object | ApiResponseError;
  pretty?: boolean;
}) {
  let message = typeof msg === "string" && pretty ? JSON.parse(msg) : msg;

  if (envConfig.backend_env !== "prod") {
    console.warn(`${prefix}: `, message);
  }
}

function error({
  prefix,
  msg,
  pretty = false,
}: {
  prefix: string;
  msg: string | object | ApiResponseError;
  pretty?: boolean;
}) {
  let message = typeof msg === "string" && pretty ? JSON.parse(msg) : msg;

  if (envConfig.backend_env !== "prod") {
    console.error(`${prefix}: `, message);
  }
}

const Logger = { debug, warn, error };

export default Logger;
