import { HasPermissionsArgs } from "../../hooks/contexts/PermissionsContext";
import { createShepherdTour } from "./common/shepherd";
import { createStepObject } from "./common/steps";

export const TOUR_CONFIG_KEY = "orgPage";

export const tour = createShepherdTour({ tourName: TOUR_CONFIG_KEY });

export const INTRO_STEP = createStepObject({
  key: "intro",
  title: `Welcome to Illumix Dashboard!`,
  text: [
    `Use <- [left arrow key] and -> [right arrow key] to move by keyboard. Let's show you around...`,
  ],
  attachTo: false,
});

export const ACCOUNT_STEP = createStepObject({
  key: "account",
  title: "Account",
  text: ["Click on the avatar to access profile."],
});

export const EDIT_TIER_STEP = createStepObject({
  key: "edit-tier",
  title: "Organization Tier Limits",
  text: ["You can edit the tier limits here."],
});

export const USERS_LIST_STEP = createStepObject({
  key: "user-list",
  title: "User Management",
  text: ["You can see all users in this organization."],
});

export const PROJECT_LIST_STEP = createStepObject({
  key: "project-list",
  title: "Project Management",
  text: ["You can see all projects in this organization."],
});

export const PROJECT_CREATE_STEP = createStepObject({
  key: "project-create",
  title: "Create Project",
  text: ["Add a new project anytime."],
});

export const CLOSING_STEP = createStepObject({
  key: "closing",
  title: "Enjoy!",
  text: ["Reach out at contact@illumix.com if you have any questions."],
  attachTo: false,
});

export function getSteps({
  hasPermission,
  isIllumixStaff,
  orgId,
  projectId,
  productId,
}: {
  hasPermission: (args: HasPermissionsArgs) => boolean;
  isIllumixStaff: boolean;
  orgId?: string;
  projectId?: string;
  productId?: string;
}) {
  const ALL_STEPS = [
    { step: INTRO_STEP },
    { step: ACCOUNT_STEP },
    {
      step: EDIT_TIER_STEP,
      permission: hasPermission({ action: "update", resource: "tiers" }),
    },
    {
      step: USERS_LIST_STEP,
      permission: hasPermission({ action: "view", resource: "users" }),
    },
    {
      step: PROJECT_LIST_STEP,
      permission: hasPermission({
        action: "view",
        resource: "projects",
        orgId: orgId,
      }),
    },
    {
      step: PROJECT_CREATE_STEP,
      permission: hasPermission({
        action: "create",
        resource: "projects",
        orgId: orgId,
      }),
    },
    { step: CLOSING_STEP },
  ];
  return ALL_STEPS;
}

const defaultExport = {
  key: TOUR_CONFIG_KEY,
  getSteps,
  tour,
};

export default defaultExport;
