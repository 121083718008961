import {
  useDispatch as useReduxDispatch,
  useSelector as useReduxSelector,
} from "react-redux";
import type { TypedUseSelectorHook } from "react-redux";
import type { ThunkAction } from "redux-thunk";
import {
  configureStore,
  combineReducers,
  Middleware,
  MiddlewareAPI,
  isRejectedWithValue, createSlice,
} from "@reduxjs/toolkit";
import type { Action } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/dist/query";

import { billingApi } from "./services/billing";
import { orgsApi } from "./services/orgs";
import { usersApi } from "./services/users";
import { projectsApi } from "./services/projects";
import { permissionsApi } from "./services/permissions";
import { apiKeysApi } from "./services/apiKeys";
import { assetsApi } from "./services/assets";
import { hyperionWebBuildsApi } from "./services/hyperionWebBuilds";
import { settingsApi } from "./services/settings";
import { tiersApi } from "./services/tier";
import Logger from "../lib/logger";
import {SDKApi} from "./services/sdk";
import {getCookie, setCookie} from "../lib/IllumixCookie";
import {URIConfig} from "../config";
import {Project} from "../types/project";

const MAX_REAUTH_TRIES = 2;
const NUM_DAYS_EXPIRE = 2;

//global state slices
const initialProjectState : {
  currentProject: Project | undefined
} = {
  currentProject: undefined,
}
const projectSlice = createSlice({
  name: 'currentProject',
  initialState: initialProjectState,
  reducers: {
    setCurrentProject: (state:any = {}, action:any) => {
      state.currentProject = action.payload;
    },
  },
});

const initialToastState : {
  toast: {
    type: 'success' | 'error',
    message: string,
  } | undefined,
} = { toast: undefined };
const globalToastSlice = createSlice({
  name: 'toast',
  initialState: initialToastState,
  reducers: {
    setGlobalToast: (state:any = {}, action:any) => {
      state.toast = action.payload;
    },
  },
});

const rootReducer = combineReducers({
  [billingApi.reducerPath]: billingApi.reducer,
  [orgsApi.reducerPath]: orgsApi.reducer,
  [usersApi.reducerPath]: usersApi.reducer,
  [projectsApi.reducerPath]: projectsApi.reducer,
  [apiKeysApi.reducerPath]: apiKeysApi.reducer,
  [permissionsApi.reducerPath]: permissionsApi.reducer,
  [assetsApi.reducerPath]: assetsApi.reducer,
  [hyperionWebBuildsApi.reducerPath]: hyperionWebBuildsApi.reducer,
  [settingsApi.reducerPath]: settingsApi.reducer,
  [tiersApi.reducerPath]: tiersApi.reducer,
  [SDKApi.reducerPath]: SDKApi.reducer,
  projects: projectSlice.reducer,
  globalToast: globalToastSlice.reducer,
});

export const rtkQueryErrorLogger: Middleware =
  (api: MiddlewareAPI) => (next) => (action) => {
    // RTK Query uses `createAsyncThunk` from redux-toolkit under the hood, so we're able to utilize these use matchers!
    if (isRejectedWithValue(action)) {
      Logger.error({ prefix: "rtkQueryErrorLogger.rejected", msg: action });
    }

    return next(action);
  };

export const userNotAuthenticatedMiddleware: Middleware =
    (api: MiddlewareAPI) => (next) => (action) => {
      if (isRejectedWithValue(action)) {
        if (action?.payload?.data?.statusCode === 401 && action.payload.data.message === 'Unauthorized') {
          if (action.payload.data.code === 1) {
            // @ts-ignore
            return next(actions.setGlobalToast({ type: 'error', message: `Looks like you're not authorized. Try refreshing the page or contact support for more help.` }));
          } else {
            const numReauthTries = Number(getCookie('reauth_count' ) || 0);
            if (numReauthTries >= MAX_REAUTH_TRIES) {
              // @ts-ignore
              return next(actions.setGlobalToast({ type: 'error', message: `There is a problem authenticating your account. Please contact support for more help.` }));
            } else {
              const today = new Date();
              setCookie('reauth_count', numReauthTries + 1, { expires: today.setDate(today.getDate() + NUM_DAYS_EXPIRE) });
              const encodedCallbackUrl = encodeURI(window.location.href);
              window.location.assign(`${URIConfig.org_uri}/authenticate?callbackUrl=${encodedCallbackUrl}`);
            }
          }
        }
        return next(action);
      }
      return next(action);
    };

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(
      billingApi.middleware,
      orgsApi.middleware,
      usersApi.middleware,
      projectsApi.middleware,
      permissionsApi.middleware,
      apiKeysApi.middleware,
      assetsApi.middleware,
      hyperionWebBuildsApi.middleware,
      settingsApi.middleware,
      tiersApi.middleware,
      SDKApi.middleware,
      rtkQueryErrorLogger,
      userNotAuthenticatedMiddleware
    ),
  devTools: process.env.REACT_APP_ENABLE_REDUX_DEV_TOOLS === "true",
});

setupListeners(store.dispatch);

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;

export type AppThunk = ThunkAction<void, RootState, null, Action<string>>;

export const useSelector: TypedUseSelectorHook<RootState> = useReduxSelector;

export const useDispatch = () => useReduxDispatch<AppDispatch>();

export const actions = {
  setCurrentProject: projectSlice.actions.setCurrentProject,
  setGlobalToast: globalToastSlice.actions.setGlobalToast,
}

export default store;
