import orgPage from "./orgPageTour";
import orgListPage from "./orgListPageTour";
import usersListPage from "./usersListPageTour";
import projectPage from "./projectPageTour";
import productPage from "./productPageTour";

const Tours = {
  orgPage,
  orgListPage,
  usersListPage,
  projectPage,
  productPage,
};

export default Tours;
