import { HasPermissionsArgs } from "../../hooks/contexts/PermissionsContext";
import { createShepherdTour } from "./common/shepherd";
import { createStepObject } from "./common/steps";

export const TOUR_CONFIG_KEY = "orgListPage";

export const tour = createShepherdTour({ tourName: TOUR_CONFIG_KEY });

export const ORG_CREATE_STEP = createStepObject({
  key: "org-create",
  title: "Create Organization",
  text: ["Create a new organization for users."],
});

export function getSteps({
  hasPermission,
  isIllumixStaff,
  orgId,
  projectId,
  productId,
}: {
  hasPermission: (args: HasPermissionsArgs) => boolean;
  isIllumixStaff: boolean;
  orgId?: string;
  projectId?: string;
  productId?: string;
}) {
  const ALL_STEPS = [
    {
      step: ORG_CREATE_STEP,
      permission: hasPermission({ action: "create", resource: "orgs" }),
    },
  ];
  return ALL_STEPS;
}

const defaultExport = {
  key: TOUR_CONFIG_KEY,
  getSteps,
  tour,
};

export default defaultExport;
