import { HasPermissionsArgs } from "../../hooks/contexts/PermissionsContext";
import { createShepherdTour } from "./common/shepherd";
import { createStepObject } from "./common/steps";

export const TOUR_CONFIG_KEY = "productPage";

export const tour = createShepherdTour({ tourName: TOUR_CONFIG_KEY });

export const THEME_SETTINGS_STEP = createStepObject({
  key: "theme-settings",
  title: "Theme",
  text: ["Update Try On experience's theme."],
});

export const HYPERION_WEB_VERSION_STEP = createStepObject({
  key: "hyperion-web-version",
  title: "Hyperion Web Version",
  text: ["Select an archived version to test an older Try On experience."],
});

export const ACTIVATE_TRY_ON_STEP = createStepObject({
  key: "activate-try-on",
  title: "Try On",
  text: ["Restart the Try On experience whenever you want."],
});

export function getSteps({
  hasPermission,
  isIllumixStaff,
  orgId,
  projectId,
  productId,
}: {
  hasPermission: (args: HasPermissionsArgs) => boolean;
  isIllumixStaff: boolean;
  orgId?: string;
  projectId?: string;
  productId?: string;
}) {
  const ALL_STEPS = [
    {
      step: THEME_SETTINGS_STEP,
      permission: hasPermission({
        action: "update",
        resource: "settings",
        resourceId: projectId,
        orgId,
      }),
    },
    { step: HYPERION_WEB_VERSION_STEP },
    { step: ACTIVATE_TRY_ON_STEP },
  ];
  return ALL_STEPS;
}

const defaultExport = {
  key: TOUR_CONFIG_KEY,
  getSteps,
  tour,
};

export default defaultExport;
