import React, { useEffect, useRef, useState } from "react";
import type { FC } from "react";
import {
  useSearchParams
} from "react-router-dom";
import { useSnackbar } from "notistack";
import {
  Box,
  ButtonBase,
  Divider,
  Hidden,
  Link,
  Drawer,
  Popover,
  Typography, Button,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";

import { useGetOrgByIdQuery } from "../../store/services/orgs";
import BoringAvatar from "../common/BoringAvatar";
import { AccountEditButton } from "./account";

import DotIcon from "../icons/Dot";
import { User } from "../../types/user";
import { ACCOUNT_STEP } from "../../shepherd/tours/orgPageTour";
import useTour from "../../hooks/useTour";
import { setLocalStorage } from "../../lib/localStorage";
import {envConfig, stripeConfig, URIConfig} from "../../config";
import { useCreateBillingLinkMutation } from "../../store/services/billing";
import ModalButton from "../common/buttons/ModalButton";
import { AmberfloProvider } from "@amberflo/uikit";
import { StripeSetupIntent } from '@amberflo/uikit/components/organisms/StripeSetupIntent'

const AccountPopover: FC<{ user: User; className?: string }> = (props) => {
  const anchorRef = useRef<HTMLButtonElement | null>(null);
  const { enqueueSnackbar } = useSnackbar();

  const [createBillinkLink] = useCreateBillingLinkMutation();

  const [open, setOpen] = useState<boolean>(false);
  const [amberfloToken, setAmberfloToken] = useState<string>();
  const [usageUrl, setUsageUrl] = useState<string>();

  const { data } = useGetOrgByIdQuery({
    id: props.user.orgId,
    include: ["tier", "settings"],
  });

  const [searchParams, setSearchParams] = useSearchParams();

  const handleOpen = (): void => {
    setOpen(true);
  };

  const handleClose = (): void => {
    setOpen(false);
  };
  
  const handleLogout = (): void => {
    window.location.assign(`${URIConfig.org_uri}/logout`);
  };

  const handleBillingClick = (): void => {
    window.open(usageUrl);
    handleClose();
  };

  // grab session token on init
  useEffect(() => {
    (async () => {
      if (['admin', 'owner', 'member'].includes(props.user.role)) {
        await createBillinkLink({
          orgId: props.user.orgId,
        })
          .unwrap()
          .then((payload) => {
            setAmberfloToken(payload.data?.sessionToken);
            setUsageUrl(payload.data?.url);
          });
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // detect successful payment method update from amberflo/stripe and then clear the search params from the url
  useEffect(() => {
    const [intent_id, intent_secret, redirect_status] = [searchParams.get('setup_intent'), searchParams.get('setup_intent_client_secret'), searchParams.get('redirect_status')];
    if (intent_id || intent_secret) {
      if (redirect_status === 'succeeded') {
        enqueueSnackbar("Payment method updated.", {
          variant: "success",
        });
      } else {
        enqueueSnackbar("Something went wrong updating your payment. Please try again.", {
          variant: "error",
        });
      }

      setSearchParams(undefined);
    }
  }, [searchParams, enqueueSnackbar, setSearchParams]);

  const { addAvailableStep } = useTour();
  useEffect(() => {
    addAvailableStep(ACCOUNT_STEP);
  }, [addAvailableStep]);

  let content = (
    <>
      <Box sx={{ px: 2 }}>
        <Box
          sx={{
            alignItems: "center",
            borderRadius: 1,
            display: "flex",
            flexDirection: "column",
            overflow: "hidden",
            backgroundColor: "background.paper",
            p: 2,
            mt: 2,
          }}
        >
          <BoringAvatar name={props.user.email} size={48} />
          <Typography color="textPrimary" variant="subtitle2">
            {props.user.name}
          </Typography>

          <Typography
            color="textSecondary"
            variant="subtitle2"
            sx={{ wordBreak: "break-word", textAlign: "center" }}
          >
            {props.user.email}
          </Typography>

          <Typography color="textSecondary" variant="overline">
            {props.user.role}
          </Typography>

          {data?.data && (
            <AccountEditButton
              user={props.user}
              org={data.data}
              size={"small"}
              variant={"text"}
            />
          )}
        </Box>
      </Box>

      {/* <Box sx={{ px: 2 }}>
        {data?.data != null && (
          <NavSection
            hideTitle={true}
            pathname={location.pathname}
            {...getMenuItems({
              orgId: data.data.id,
              viewOrgs: permissions.hasPermission({
                action: "view",
                resource: "orgs",
              }),
            })}
          />
        )}
      </Box> */}

      <Divider />

      {envConfig.backend_env === "sandbox" && (
        <Box
          sx={{
            px: 2,
            pt: 1,
            pb: 1,
            display: "flex",
            justifyContent: "center",
          }}
        >
          <LoadingButton
            onClick={() => {
              setLocalStorage({
                key: "tour",
                value: JSON.stringify({}),
              });
              enqueueSnackbar("Tour history cleared", {
                variant: "success",
              });
            }}
            color="primary"
            variant="outlined"
            size="small"
            sx={{ width: "75%" }}
          >
            Reset Tutorials
          </LoadingButton>
        </Box>
      )}
      {['admin', 'owner', 'member'].includes(props.user.role) && <Box
        sx={{px: 2, pt: 1, pb: 1, display: "flex", justifyContent: "center"}}
      >
        <Button
          onClick={handleBillingClick}
          color="primary"
          variant="outlined"
          size="small"
          sx={{width: "75%"}}
        >
          Billing & Usage
        </Button>
      </Box>}

      {['admin', 'owner'].includes(props.user.role) && <Box
        sx={{px: 2, pt: 1, pb: 1, display: "flex", justifyContent: "center"}}
      >
        <ModalButton
          modalKey={`account::payment`}
          modalText="Edit Payment Method"
          modalMaxWidth="sm"
          className={'EDIT_TIER_STEP.attachTo?.element.substring(1)'}
          textButton={{
            color: 'primary',
            variant: 'outlined',
            size: 'small',
            sx: {width: '75%'},
            text: 'Edit Payment Method',
          }}
        >
          {amberfloToken && <AmberfloProvider
            session={amberfloToken}
            theme={{
              fontFamily: '"Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"'
            }}
          >
            <Box
              sx={[
                {
                  px: 2,
                  pt: 1,
                  pb: 1,
                  display: "flex",
                  justifyContent: "center",
                },
                (theme) => ({
                  // amberflo class overrides
                  '& span.aflo-text': {
                    color: theme.palette.primary.main,
                  },
                  '& .aflo-content': {
                    fontSize: theme.typography.subtitle2,
                    color: theme.palette.text.secondary,
                  },
                  '& .aflo-title, & h5': {
                    color: theme.palette.text.primary,
                  },
                  '& .aflo-title': {
                    fontSize: theme.typography.subtitle1,
                  },
                  '& .aflo-stack .aflo-button.white': {
                    fontSize: theme.typography.subtitle1,
                    backgroundColor: theme.palette.background.paper,
                    borderRadius: '0.5rem',
                  },
                  '& .aflo-stack .aflo-button.primary': {
                    backgroundColor: theme.palette.primary.main,
                    borderRadius: '0.5rem',
                  },
                  '& .aflo-stack .aflo-button.primary > span': {
                    color: 'white',
                  },
                  '& div.aflo-component > div.aflo-view > form.aflo-form > .StripeElement': {
                    backgroundColor: theme.palette.neutral?.['100'],
                    padding: 1,
                    borderRadius: '0.25rem',
                  },
                }),
              ]}
            >
              <StripeSetupIntent
                publishableKey={stripeConfig.publishable_key}
                returnUrl={window.location.href}/>
            </Box>
          </AmberfloProvider>}
        </ModalButton>
      </Box>}

      <Box
        sx={{ px: 2, pt: 1, pb: 2, display: "flex", justifyContent: "center" }}
      >
        <LoadingButton
          loading={false}
          onClick={handleLogout}
          color="primary"
          variant="outlined"
          size="small"
          sx={{ width: "75%" }}
        >
          Logout
        </LoadingButton>
      </Box>

      <Divider />

      <Box
        sx={{
          p: 2,
          display: "flex",
          justifyContent: "space-around",
          alignContent: "center",
          alignItems: "center",
        }}
      >
        <Link
          component="a"
          href="https://illumix.com/tou"
          underline="hover"
          target={"_blank"}
        >
          <Typography sx={{ fontSize: 12 }} color="textSecondary">
            Terms of Service
          </Typography>
        </Link>

        <DotIcon sx={{ m: 1, fontSize: 10, color: "text.secondary" }} />

        <Link
          component="a"
          href="https://illumix.com/privacy-policy"
          underline="hover"
          target={"_blank"}
        >
          <Typography sx={{ fontSize: 12 }} color="textSecondary">
            Privacy Notice
          </Typography>
        </Link>
      </Box>

      <Box
        sx={{
          px: 2,
          pb: 2,
          display: "flex",
          justifyContent: "space-around",
          alignContent: "center",
          alignItems: "center",
        }}
      >
        <Link
          color="textSecondary"
          component="a"
          underline="none"
          href="mailto:contact@illumix.com"
          sx={{ fontSize: 12 }}
        >
          <Typography sx={{ fontSize: 12 }} color="textSecondary">
            contact@illumix.com
          </Typography>
        </Link>
      </Box>
    </>
  );

  return (
    <>
      <Box
        className={`${ACCOUNT_STEP.attachTo?.element.substring(1)} ${
          props.className ? props.className : ""
        }`}
        component={ButtonBase}
        onClick={handleOpen}
        ref={anchorRef}
        sx={{
          alignItems: "center",
          display: "flex",
          borderRadius: "50%",
        }}
      >
        <BoringAvatar name={props.user.email} size={32} />
      </Box>

      <Hidden smUp>
        <Drawer
          anchor="right"
          onClose={handleClose}
          open={open}
          PaperProps={{
            sx: {
              backgroundColor: "background.paper",
              width: 280,
            },
          }}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden smDown>
        <Popover
          anchorEl={anchorRef.current}
          anchorOrigin={{
            horizontal: "center",
            vertical: "bottom",
          }}
          keepMounted
          onClose={handleClose}
          open={open}
          PaperProps={{
            sx: {
              width: 260,
              backgroundColor: "background.paper",
            },
          }}
        >
          {content}
        </Popover>
      </Hidden>
    </>
  );
};

export default AccountPopover;
