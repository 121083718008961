import { createApi } from "@reduxjs/toolkit/query/react";

import { axiosBaseQuery } from "./fetch";
import { serverConfig } from "../../config";
import { ApiKey } from "../../types/apiKey";
import { ApiResponse } from "../../types/response";

const TAG = "ApiKeys";
const LIST = "LIST";

export const apiKeysApi = createApi({
  reducerPath: "apiKeysApi",
  baseQuery: axiosBaseQuery({
    baseUrl: `${serverConfig.url}/${serverConfig.endpoint_prefix}/${serverConfig.version}/`,
  }),
  tagTypes: [TAG],
  endpoints: (builder) => ({
    getApiKeyById: builder.query<ApiResponse<ApiKey>, { id: string }>({
      query: ({ id }) => ({ url: `api_keys/${id}`, method: "GET" }),
      providesTags: (result, err) =>
        result?.data ? [{ type: TAG, id: result.data.id }] : [],
    }),

    getApiKeys: builder.query<ApiResponse<ApiKey[]>, { projectId?: string }>({
      query: ({ projectId }) => ({
        url: `api_keys${projectId ? `?projectId=${projectId}` : ``}`,
        method: "GET",
      }),
      providesTags: (result, err) =>
        result?.data != null
          ? [
              { type: TAG, id: LIST },
              ...result.data.map((i) => ({ type: TAG, id: i.id } as const)),
            ]
          : [],
    }),

    createApiKey: builder.mutation<
      ApiResponse<ApiKey>,
      { data: Pick<ApiKey, "projectId" | "updatedBy"> }
    >({
      query: ({ data }) => ({
        url: `api_keys`,
        method: "POST",
        data: data,
      }),
      invalidatesTags: (result, err) =>
        result?.data ? [{ type: TAG, id: LIST }] : [],
    }),

    updateApiKey: builder.mutation<
      ApiResponse<ApiKey>,
      { id: string; data: Pick<ApiKey, "disabled" | "updatedBy"> }
    >({
      query: ({ id, data }) => ({
        url: `api_keys/${id}`,
        method: "PUT",
        data: data,
      }),
      invalidatesTags: (result, err) =>
        result?.data ? [{ type: TAG, id: LIST }] : [],
    }),

    deleteApiKey: builder.mutation<ApiResponse<ApiKey>, { id: string }>({
      query: ({ id }) => ({
        url: `api_keys/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: (result, err) =>
        result?.data ? [{ type: TAG, id: LIST }] : [],
    }),
  }),
});

export const {
  useGetApiKeyByIdQuery,
  useGetApiKeysQuery,
  useCreateApiKeyMutation,
  useUpdateApiKeyMutation,
  useDeleteApiKeyMutation,
} = apiKeysApi;
