import { createApi } from "@reduxjs/toolkit/query/react";

import { axiosBaseQuery } from "./fetch";
import { serverConfig } from "../../config";
import { ApiResponse } from "../../types/response";

const TAG = "HyperionWebBuilds";

export const hyperionWebBuildsApi = createApi({
  reducerPath: "hyperionWebBuildsApi",
  baseQuery: axiosBaseQuery({
    baseUrl: `${serverConfig.url}/${serverConfig.endpoint_prefix}/${serverConfig.version}/`,
  }),
  tagTypes: [TAG],
  endpoints: (builder) => ({
    getHyperionWebBuilds: builder.query<
      ApiResponse<{ version: string }[]>,
      void
    >({
      query: () => ({
        url: `hyperion_web_builds`,
        method: "GET",
      }),
    }),
  }),
});

export const { useGetHyperionWebBuildsQuery } = hyperionWebBuildsApi;
