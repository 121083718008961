import { FC, useState } from "react";
import * as Yup from "yup";
import {
  Box,
  Dialog,
  Button,
  Typography,
  TextField,
  Grid,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useSnackbar } from "notistack";
import { useFormik } from "formik";

import Logger from "../../../lib/logger";
import { useUpdateUserMutation } from "../../../store/services/users";
import PencilAltIcon from "../../icons/PencilAlt";

import { Org } from "../../../types/org";
import { User } from "../../../types/user";

const AccountFormikWrapper: FC<{
  user: User;
  org: Org;
  setIsModalOpen: (b: boolean) => void;
}> = (props) => {
  const { enqueueSnackbar } = useSnackbar();
  const [updateUser] = useUpdateUserMutation();

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: props.user.name,
      email: props.user.email,
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().max(50).required("Name is required"),
    }),
    onSubmit: async (
      values,
      { resetForm, setErrors, setStatus, setSubmitting }
    ): Promise<void> => {
      Logger.debug({ prefix: "UserForm.values", msg: values });

      await updateUser({
        id: props.user.id,
        data: { ...props.user, name: values.name },
      })
        .unwrap()
        .then((payload) => {
          enqueueSnackbar(`Profile updated`, {
            variant: "success",
          });

          resetForm();
          setStatus({ success: true });
          setSubmitting(false);
          props.setIsModalOpen(false);
        })
        .catch((error) => {
          Logger.error({ prefix: "UserForm.err ", msg: error });

          setStatus({ success: false });
          setErrors({ name: "Error updating account" });
          setSubmitting(false);

          enqueueSnackbar(`There was an error updating account`, {
            variant: "error",
          });
        });
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid container spacing={4}>
        <Grid item md={12} xs={12}>
          <TextField
            error={Boolean(formik.touched.name && formik.errors.name)}
            fullWidth
            helperText={formik.touched.name && formik.errors.name}
            label="Name"
            name="name"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={formik.values.name}
            variant="outlined"
          />
        </Grid>
        <Grid item md={12} xs={12}>
          <TextField
            fullWidth
            helperText={"You cannot change your email"}
            label="Email Address"
            name="email"
            type="email"
            value={formik.values.email}
            variant="outlined"
            disabled
          />
        </Grid>
      </Grid>

      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          p: 2,
        }}
      >
        <LoadingButton
          loading={formik.isSubmitting}
          color="primary"
          disabled={formik.isSubmitting}
          type="submit"
          variant="contained"
        >
          Save Changes
        </LoadingButton>
      </Box>
    </form>
  );
};

const AccountEditButton: FC<{
  user: User;
  org: Org;
  fullWidth?: boolean;
  size?: "small" | "medium" | "large";
  color?: "inherit" | "primary" | "secondary";
  variant?: "contained" | "text" | "outlined";
}> = (props) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <>
      <Box sx={{ mx: "auto" }}>
        <Button
          fullWidth={props.fullWidth || false}
          color={props.color || undefined}
          size={props.size || "medium"}
          onClick={() => setIsModalOpen((b) => !b)}
          startIcon={<PencilAltIcon fontSize="small" />}
          variant={props.variant || "contained"}
        >
          Edit Profile
        </Button>
      </Box>

      <Dialog
        maxWidth="lg"
        onClose={() => setIsModalOpen(false)}
        open={isModalOpen}
      >
        <Box sx={{ p: 3 }}>
          <Typography
            marginBottom={3}
            align="center"
            color="textPrimary"
            gutterBottom
            variant="h6"
          >
            Edit Profile
          </Typography>
          <AccountFormikWrapper
            setIsModalOpen={setIsModalOpen}
            user={props.user}
            org={props.org}
          />
        </Box>
      </Dialog>
    </>
  );
};

export default AccountEditButton;
