export type Step = {
  id: string;
  title: string;
  text: string[];
  buttons: { classes: string; text: string; action: () => void }[];
  attachTo?: {
    element: string;
    on: string;
  };
  canClickTarget: boolean;
};

export function createStepObject({
  title,
  text,
  key,
  attachTo = true,
}: {
  title: string;
  text: string[];
  key: string;
  attachTo?: boolean;
}) {
  const stepId = `shepherd-step-${key}`;

  const step: Step = {
    id: stepId,
    title: title,
    text: text,
    buttons: [],
    canClickTarget: false,
    attachTo: attachTo
      ? {
          element: `.${stepId}-tour-anchor`,
          on: "auto" as const,
        }
      : undefined,
  };

  return step;
}

export function stepAnchorElementExists(step: Step) {
  return (
    (
      document.getElementsByClassName(
        (step.attachTo?.element || "").substring(1)
      ) || []
    ).length > 0
  );
}
