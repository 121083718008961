import { createApi } from "@reduxjs/toolkit/query/react";

import { serverConfig } from "../../config";
import { ApiKey } from "../../types/apiKey";
import { Project } from "../../types/project";
import { ApiResponse } from "../../types/response";
import createQueryParams from "../../utils/createQueryParams";
import { axiosBaseQuery } from "./fetch";

const TAG = "Projects";
const LIST = "LIST";

export const projectsApi = createApi({
  reducerPath: "projectsApi",
  baseQuery: axiosBaseQuery({
    baseUrl: `${serverConfig.url}/${serverConfig.endpoint_prefix}/${serverConfig.version}/`,
  }),
  tagTypes: [TAG],
  endpoints: (builder) => ({
    getProjectById: builder.query<
      ApiResponse<Project, { apiKeys?: ApiKey[] }>,
      { id: string; include?: "api_keys"[] }
    >({
      query: ({ id, include = [] }) => ({
        url: `projects/${id}${createQueryParams({
          include: include.join(","),
        })}`,
        method: "GET",
      }),
      providesTags: (result, err) =>
        result?.data ? [{ type: TAG, id: result.data.id }] : [],
    }),

    getProjects: builder.query<
      ApiResponse<Project[]>,
      { orgId?: string; userId?: string }
    >({
      query: ({ orgId, userId }) => ({
        url: `projects${createQueryParams({ orgId, userId })}`,
        method: "GET",
      }),
      providesTags: (result, err, queries) =>
        result?.data != null
          ? [
              { type: TAG, id: LIST },
              ...result.data.map((i) => ({ type: TAG, id: i.id } as const)),
            ]
          : [],
    }),

    createProject: builder.mutation<
      ApiResponse<Project>,
      { data: Omit<Project, "id" | "createdAt" | "updatedAt"> }
    >({
      query: ({ data }) => ({
        url: `projects`,
        method: "POST",
        data: data,
      }),
      invalidatesTags: (result, err) =>
        result?.data ? [{ type: TAG, id: LIST }] : [],
    }),

    updateProject: builder.mutation<
      ApiResponse<Project>,
      { id: string; data: Partial<Project> }
    >({
      query: ({ id, data }) => ({
        url: `projects/${id}`,
        method: "PUT",
        data: data,
      }),
      invalidatesTags: (result, err) =>
        result?.data
          ? [
              { type: TAG, id: result.data.id },
              { type: TAG, id: LIST },
            ]
          : [],
    }),

    deleteProject: builder.mutation<ApiResponse<Project>, { id: string }>({
      query: ({ id }) => ({
        url: `projects/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: (result, err) =>
        result?.data ? [{ type: TAG, id: LIST }] : [],
    }),
  }),
});

export const {
  useGetProjectByIdQuery,
  useGetProjectsQuery,
  useCreateProjectMutation,
  useUpdateProjectMutation,
  useDeleteProjectMutation,
} = projectsApi;
