import { FC, useEffect } from "react";
import { GlobalStyles } from "@mui/system";
import { useTheme } from "@mui/material/styles";

import Logger from "../lib/logger";

const ShepherdTourTheme: FC = (props) => {
  const theme = useTheme();

  useEffect(() => {
    Logger.debug({ prefix: "ShepherdTourTheme.Theme", msg: theme as object });
  }, [theme]);

  return (
    <>
      <GlobalStyles
        // @ts-ignore : Having issues with CSS property in JSX
        styles={{
          ".shepherd-button": {
            background: "#3288e6",
            border: "0",
            borderRadius: "3px",
            color: "hsla(0, 0%, 100%, 0.75)",
            cursor: "pointer",
            marginRight: "0.5rem",
            padding: "0.5rem 1.5rem",
            transition: "all 0.5s ease",
          },
          ".shepherd-button:not(:disabled):hover": {
            background: "#196fcc",
            color: "hsla(0, 0%, 100%, 0.75)",
          },
          ".shepherd-button.shepherd-button-secondary": {
            background: "#f1f2f3",
            color: "rgba(0, 0, 0, 0.75)",
          },
          ".shepherd-button.shepherd-button-secondary:not(:disabled):hover": {
            background: "#d6d9db",
            color: "rgba(0, 0, 0, 0.75)",
          },
          ".shepherd-button:disabled": {
            cursor: "not-allowed",
          },
          ".shepherd-footer": {
            borderBottomLeftRadius: "5px",
            borderBottomRightRadius: "5px",
            display: "flex",
            justifyContent: "flex-end",
            padding: "0 0.75rem 0.75rem",
          },
          ".shepherd-footer .shepherd-button:last-child": {
            marginRight: "0",
          },
          ".shepherd-cancel-icon": {
            background: "transparent",
            border: "none",
            color: "hsla(0, 0%, 50.2%, 0.75)",
            fontSize: "2em",
            cursor: "pointer",
            fontWeight: "400",
            margin: 0,
            padding: 0,
            transition: "color 0.5s ease",
          },
          ".shepherd-cancel-icon:hover": {
            color: "rgba(0, 0, 0, 0.75)",
          },
          ".shepherd-has-title .shepherd-content .shepherd-cancel-icon": {
            color: "hsla(0, 0%, 50.2%, 0.75)",
          },
          ".shepherd-has-title .shepherd-content .shepherd-cancel-icon:hover": {
            color: "rgba(0, 0, 0, 0.75)",
          },
          ".shepherd-title": {
            color: "rgba(0, 0, 0, 0.75)",
            display: "flex",
            fontSize: "1rem",
            fontWeight: "400",
            flex: "1 0 auto",
            margin: "0",
            padding: "0",
          },
          ".shepherd-header": {
            alignItems: "center",
            borderTopLeftRadius: "5px",
            borderTopRightRadius: "5px",
            display: "flex",
            justifyContent: "flex-end",
            lineHeight: "2em",
            padding: "0.75rem 0.75rem 0",
          },
          ".shepherd-has-title .shepherd-content .shepherd-header": {
            background: "#e6e6e6",
            padding: "1em",
          },
          ".shepherd-text": {
            color: "rgba(0, 0, 0, 0.75)",
            fontSize: "1rem",
            lineHeight: "1.3em",
            padding: "0.75em",
          },
          ".shepherd-text p": {
            marginTop: "0",
          },
          ".shepherd-text p:last-child": {
            marginBottom: "0",
          },
          ".shepherd-content": {
            borderRadius: "5px",
            outline: "none",
            padding: "0",
          },
          ".shepherd-element": {
            background: "#fff",
            borderRadius: "5px",
            boxShadow: "0 1px 4px rgba(0, 0, 0, 0.2)",
            maxWidth: "400px",
            opacity: "0",
            outline: "none",
            transition: "opacity 0.3s, visibility 0.3s",
            visibility: "hidden",
            width: "100%",
            zIndex: "9999",
          },
          ".shepherd-enabled.shepherd-element": {
            opacity: "1",
            visibility: "visible",
          },
          ".shepherd-element[data-popper-reference-hidden]:not(.shepherd-centered)":
            {
              opacity: "0",
              pointerEvents: "none",
              visibility: "hidden",
            },
          ".shepherd-element, .shepherd-element *, .shepherd-element :after, .shepherd-element :before":
            {
              boxSizing: "border-box",
            },
          ".shepherd-arrow, .shepherd-arrow:before": {
            position: "absolute",
            width: "16px",
            height: "16px",
            zIndex: "-1",
          },
          ".shepherd-arrow:before": {
            content: '""',
            transform: "rotate(45deg)",
            background: "#fff",
          },
          ".shepherd-element[data-popper-placement^='top'] > .shepherd-arrow": {
            bottom: "-8px",
          },
          ".shepherd-element[data-popper-placement^='bottom'] > .shepherd-arrow":
            {
              top: "-8px",
            },
          ".shepherd-element[data-popper-placement^='left'] > .shepherd-arrow":
            {
              right: "-8px",
            },
          ".shepherd-element[data-popper-placement^='right'] > .shepherd-arrow":
            {
              left: "-8px",
            },
          ".shepherd-element.shepherd-centered > .shepherd-arrow": {
            opacity: "0",
          },
          ".shepherd-element.shepherd-has-title[data-popper-placement^='bottom'] > .shepherd-arrow:before":
            {
              backgroundColor: "#e6e6e6",
            },
          ".shepherd-target-click-disabled.shepherd-enabled.shepherd-target, .shepherd-target-click-disabled.shepherd-enabled.shepherd-target *":
            {
              pointerEvents: "none",
            },
          ".shepherd-modal-overlay-container": {
            height: "0",
            left: "0",
            opacity: "0",
            overflow: "hidden",
            pointerEvents: "none",
            position: "fixed",
            top: "0",
            transition: "all 0.3s ease-out, height 0ms 0.3s, opacity 0.3s 0ms",
            width: "100vw",
            zIndex: "9997",
          },
          ".shepherd-modal-overlay-container.shepherd-modal-is-visible": {
            height: "100vh",
            opacity: "0.5",
            transition: "all 0.3s ease-out, height 0s 0s, opacity 0.3s 0s",
          },
          ".shepherd-modal-overlay-container.shepherd-modal-is-visible path": {
            pointerEvents: "all",
          },

          "body .shepherd-element": {},
          "body .shepherd-content": {
            padding: "0 1em",
          },
          "body .shepherd-element.shepherd-has-title[data-popper-placement^=bottom]>.shepherd-arrow:before":
            {
              background: `#fff`,
              backgroundColor: `#fff`,
            },
          "body .shepherd-title": {
            ...theme.typography.h6,
          },
          "body .shepherd-text": {
            ...theme.typography.body2,
            margin: "10px 0",
            paddingLeft: "1em",
            paddingRight: "1em",
          },
          "body .shepherd-has-title .shepherd-content .shepherd-header": {
            background: `#fff`,
            borderBottom: `1px solid ${theme.palette.grey[300]}`,
          },
          "body .shepherd-button": {
            ...theme.typography.body2,

            color: `${theme.palette.primary.contrastText}`,
            borderRadius: "8px",
          },
          "body .shepherd-button-primary.shepherd-button": {
            background: `${theme.palette.primary.main}`,
            color: `${
              theme.palette.mode === "dark"
                ? theme.palette.text.primary
                : theme.palette.primary.contrastText
            }`,
          },
          "body .shepherd-button.shepherd-button-primary:not(:disabled):hover":
            {
              background: `${theme.palette.primary.light}`,
            },
          "body .shepherd-button.shepherd-button-secondary": {
            background: `transparent`,
            backgroundColor: `transparent`,
          },
          "body .shepherd-button.shepherd-button-secondary:not(:disabled):hover":
            {
              background: `transparent`,
              backgroundColor: `transparent`,
            },
        }}
      />
    </>
  );
};

export default ShepherdTourTheme;
