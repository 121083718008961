import {LazyExoticComponent, FC, Suspense, lazy} from "react";
import type { RouteObject } from "react-router";

import AuthGuard from "./components/AuthGuard";
import LoadingScreen from "./components/LoadingScreen";
import MainLayout from "./components/MainLayout";
import Error403 from "./components/errors/Error403";
import DashboardLayoutV2 from "./components/dashboard-v2/DashboardLayoutV2";
import OrgFetchWrapper from "./components/OrgFetchWrapper";
import {Navigate} from "react-router";

const Loadable = (Component: LazyExoticComponent<FC<any>>) => (props: any) =>
  (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  );

// Error components
const Error401 = Loadable(lazy(() => import("./components/errors/Error401")));
const Error404 = Loadable(lazy(() => import("./components/errors/Error404")));
const Error500 = Loadable(lazy(() => import("./components/errors/Error500")));

const Contact = Loadable(lazy(() => import("./components/Contact")));
const Privacy = Loadable(lazy(() => import("./components/Privacy")));
const Terms = Loadable(lazy(() => import("./components/Terms")));

// Auth0 Authenticate
const Authenticate = Loadable(lazy(() => import("./components/Authenticate")));
const PostAuthenticate = Loadable(lazy(() => import("./components/PostAuthenticate")));
const Logout = Loadable(lazy(() => import("./components/Logout")));

// Pages
const Home = Loadable(lazy(() => import("./pages/Home")));
const Blank = Loadable(lazy(() => import("./pages/Blank")));

// Dashboard pages
const Users = Loadable(lazy(() => import("./pages/dashboard/Users")));
const Organization = Loadable(
  lazy(() => import("./pages/dashboard/Organization"))
);
const OrganizationList = Loadable(
  lazy(() => import("./pages/dashboard/OrganizationList"))
);
const Projects = Loadable(
  lazy(() => import("./pages/dashboard/Projects"))
);

// Admin pages
const OrganizationProfile = Loadable(lazy(() => import("./pages/admin/OrganizationProfile")));

const routes: RouteObject[] = [
  {
    path: "t",
    children: [
      {
        path: "",
        element: (
          <Blank title={"Error: Forbidden | Illumix"}>
            <Error403 hideButton={true} />
          </Blank>
        ),
      },
    ],
  },
  {
    path: "forbidden",
    element: (
      <Blank title={"Error: Forbidden | Illumix"}>
        <Error403 hideButton={true} />
      </Blank>
    ),
  },
  {
    path: "admin",
    element: (
      <AuthGuard>
        <DashboardLayoutV2 />
      </AuthGuard>
    ),
    children: [
      {
        path: '',
        element: <Navigate to={'/'} />
      },
      {
        path: "organizations",
        children: [
          {
            path: '',
            element: <Navigate to={'list'} />,
          },
          {
            path: 'list',
            element: <OrganizationList />,
          },
          {
            path: ":paramOrgId",
            element: <OrgFetchWrapper />,
            children: [
              {
                path: '',
                element: <OrganizationProfile />
              },
            ],
          },
        ]
      },
      {
        path: "overview",
        element: <Organization />,
      },
      {
        path: "users",
        element: <Users />,
      },
    ],
  },
  {
    path: "organizations",
    element: (
      <AuthGuard>
        <DashboardLayoutV2 />
      </AuthGuard>
    ),
    children: [
      {
        path: '',
        element: <Navigate to={'/'} />
      },
      {
        path: "list",
        element: <OrganizationList />,
      },
      {
        path: ":paramOrgId",
        element: <OrgFetchWrapper />,
        children: [
          {
            path: '',
            element: <Navigate to={'projects'} />
          },
          {
            path: "projects",
            element: <Projects />,
          },
          {
            path: "users",
            element: <Users />,
          },
        ],
      },
    ],
  },
  {
    path: "",
    element: (<MainLayout><Home /></MainLayout>),
  },
  {
    path: "authenticate",
    element: <Authenticate />,
  },
  {
    path: "postAuthenticate",
    element: <PostAuthenticate />,
  },
  {
    path: "logout",
    element: <Logout />,
  },
  {
    path: "privacy",
    element: (
      <MainLayout>
        <Blank title={"Privacy | Illumix"}>
          <Privacy />
        </Blank>
      </MainLayout>
    ),
  },
  {
    path: "terms",
    element: (
      <MainLayout>
        <Blank title={"Terms | Illumix"}>
          <Terms />
        </Blank>
      </MainLayout>
    ),
  },
  {
    path: "contact",
    element: (
      <MainLayout>
        <Blank title={"Contact | Illumix"}>
          <Contact />
        </Blank>
      </MainLayout>
    ),
  },
  {
    path: "401",
    element: (
      <MainLayout>
        <Blank title={"Error: Authorization Required | Illumix"}>
          <Error401 />
        </Blank>
      </MainLayout>
    ),
  },
  {
    path: "403",
    element: (
      <MainLayout>
        <Blank title={"Error: Forbidden | Illumix"}>
          <Error403 />
        </Blank>
      </MainLayout>
    ),
  },
  {
    path: "404",
    element: (
      <MainLayout>
        <Blank title={"Error: Not Found | Illumix"}>
          <Error404 />
        </Blank>
      </MainLayout>
    ),
  },
  {
    path: "500",
    element: (
      <MainLayout>
        <Blank title={"Error: Server Error | Illumix"}>
          <Error500 />
        </Blank>
      </MainLayout>
    ),
  },
  {
    path: "*",
    element: (
      <MainLayout>
        <Blank title={"Not Found | Illumix"}>
          <Error404 />
        </Blank>
      </MainLayout>
    ),
  },
];

export default routes;
