import type { FC } from "react";
// import { useEffect } from "react";
// import NProgress from "nprogress";
import { Box } from "@mui/material";

// import Logo from "./common/Logo";

const LoadingScreen: FC<{ whiteBackground?: boolean }> = (props) => {
  // useEffect(() => {
  //   NProgress.start();

  //   return (): void => {
  //     NProgress.done();
  //   };
  // }, []);

  return (
    <Box
      sx={{
        backgroundColor: props.whiteBackground ? undefined : "background.paper",
        minWidth: "100%",
        minHeight: "100%",
      }}
    >
      {/* <Logo size={42} sx={{ position: "fixed", top: 5, left: 5 }} /> */}
    </Box>
  );
};

export default LoadingScreen;
