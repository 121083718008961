import {createContext, useCallback, useEffect, useState} from "react";
import type { FC, ReactNode } from "react";
import {auth0Config} from "../../config";
import {jwtDecode} from "jwt-decode";
import {getCookie} from "../../lib/IllumixCookie";

export interface AuthContextValue {
  isAuthenticated: boolean;
  userId: undefined | string;
  doAuthCheck: () => void;
}

const AuthContext = createContext<AuthContextValue>({
  isAuthenticated: false,
  userId: undefined,
  doAuthCheck: () => {},
});

export const AuthProvider: FC<{
  children?: ReactNode;
}> = (props) => {
  const { children } = props;
  const [isAuthenticated, setIsAuthenticated] = useState(true);
  const [userId, setUserId] = useState<string | undefined>(undefined);
  
  useEffect(() => {
    const id = setInterval(() => setIsAuthenticated(isTokenValid()), 15000);
    setIsAuthenticated(isTokenValid());
    return () => clearInterval(id);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  
  const isTokenValid = useCallback(() => {
    const accessToken = getCookie('accessToken');
    
    // no token cookie found
    if (!accessToken) return false;
    const decodedJwt = jwtDecode(accessToken) as any;
    
    // set user id we get from parsed auth0 token
    if (!userId) {
      setUserId(decodedJwt?.[auth0Config.illumix_key]);
    }
    const expiration = decodedJwt.exp;
    
    // token has expired
    if (new Date() > new Date(expiration * 1000)) {
      return false;
    }
    return true;
  }, [userId]);
  
  const doAuthCheck = useCallback(() => {
    setIsAuthenticated(isTokenValid());
  }, [setIsAuthenticated, isTokenValid])

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        userId,
        doAuthCheck,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const AuthConsumer = AuthContext.Consumer;

export default AuthContext;
