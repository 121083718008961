import axios from "axios";
import AxiosMockAdapter from "axios-mock-adapter";
import { serverConfig } from "../config";

const axiosMockInstance = axios.create();
axiosMockInstance.interceptors.response.use(
  (response) => response,
  (error) =>
    Promise.reject(
      (error.response && error.response.data) ||
        "Axios Mock Instance had an error!"
    )
);
export const mock = new AxiosMockAdapter(axiosMockInstance, {
  delayResponse: 0,
});

const axiosInstance = axios.create({
  baseURL: `${serverConfig.url}/${serverConfig.version}`,
});
export default axiosInstance;
