function validateValues<
  T extends object & {
    [propertyName: string]: any;
  }
>(obj: T): T {
  Object.keys(obj).forEach((key) => {
    if (obj[key] == null) throw Error(`CONFIG INVALID VALUE FOR KEY - ${key}`);
  });

  return obj;
}

export const URIConfig = validateValues({
  domain: process.env.REACT_APP_DOMAIN!,
  org_uri: process.env.REACT_APP_ORG_URI!,
  venue_v1_uri: process.env.REACT_APP_VENUE_V1_URI!,
  venue_v2_uri: process.env.REACT_APP_VENUE_V2_URI!,
  tryon_uri: process.env.REACT_APP_TRYON_URI!,
});

export const auth0Config = validateValues({
  client_id: process.env.REACT_APP_AUTH0_CLIENT_ID!,
  domain: process.env.REACT_APP_AUTH0_DOMAIN!,
  audience: process.env.REACT_APP_AUTH0_AUDIENCE!,
  illumix_key: process.env.REACT_APP_AUTH0_CUSTOM_FIELD!,
});

export const gtmConfig = validateValues({
  container_id: process.env.REACT_APP_GTM_CONTAINER_ID!,
});

export const sdkConfig = validateValues({
  sdk_url: process.env.REACT_APP_SDK_URL!,
});

export const serverConfig = validateValues({
  url: process.env.REACT_APP_SERVER_URL!,
  version: process.env.REACT_APP_SERVER_VERSION!,
  endpoint_prefix: process.env.REACT_APP_SERVER_ENDPOINT_PREFIX!,
});

export const envConfig = validateValues({
  env: process.env.NODE_ENV!,
  backend_env: process.env.REACT_APP_BACKEND_ENV!,
});

export const stripeConfig = validateValues({
  publishable_key: process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY!,
});

export const mapsConfig = validateValues({
  api_key: process.env.REACT_APP_MAPS_API_KEY!,
  experience_manager_map_id: process.env.REACT_APP_EXPERIENCE_MANAGER_MAP_ID!,
  location_map_id: process.env.REACT_APP_LOCATION_MAP_ID!,
});
