import { createApi } from "@reduxjs/toolkit/query/react";
import { serverConfig } from "../../config";
import { ApiResponse } from "../../types/response";
import { User } from "../../types/user";
import createQueryParams from "../../utils/createQueryParams";
import { axiosBaseQuery } from "./fetch";

const TAG = "Users";
const LIST = "LIST";

export const usersApi = createApi({
  reducerPath: "usersApi",
  baseQuery: axiosBaseQuery({
    baseUrl: `${serverConfig.url}/${serverConfig.endpoint_prefix}/${serverConfig.version}/`,
  }),
  tagTypes: [TAG],
  endpoints: (builder) => ({
    getUserById: builder.query<
      ApiResponse<User, { permissions?: Record<string, string[]> }>,
      { id: string; include?: "permissions"[] }
    >({
      query: ({ id, include = [] }) => ({
        url: `users/${id}${createQueryParams({ include: include.join(",") })}`,
        method: "GET",
      }),
      providesTags: (result, err) =>
        result?.data ? [{ type: TAG, id: result.data.id }] : [],
    }),

    getUsers: builder.query<ApiResponse<User[]>, { orgId?: string }>({
      query: ({ orgId }) => ({
        url: `users${orgId ? `?orgId=${orgId}` : ""}`,
        method: "GET",
      }),
      providesTags: (result, err) =>
        result?.data != null
          ? [
              { type: TAG, id: LIST },
              ...result.data.map((i) => ({ type: TAG, id: i.id } as const)),
            ]
          : [],
    }),

    createUser: builder.mutation<
      ApiResponse<User>,
      { data: Omit<User, "id" | "createdAt" | "updatedAt"> }
    >({
      query: ({ data }) => ({
        url: `users`,
        method: "POST",
        data: data,
      }),
      invalidatesTags: (result, err) =>
        result?.data ? [{ type: TAG, id: LIST }] : [],
    }),

    reinviteUser: builder.mutation<ApiResponse<undefined>, { id: string }>({
      query: ({ id }) => ({
        url: `users/${id}/invite`,
        method: "POST",
      }),
      invalidatesTags: (result, err) =>
        result?.data ? [{ type: TAG, id: LIST }] : [],
    }),

    updateUser: builder.mutation<
      ApiResponse<User>,
      { id: string; data: Partial<User> }
    >({
      query: ({ id, data }) => ({
        url: `users/${id}`,
        method: "PUT",
        data: data,
      }),
      invalidatesTags: (result, err) =>
        result?.data ? [{ type: TAG, id: result.data.id }] : [],
    }),

    deleteUser: builder.mutation<ApiResponse<User>, { id: string }>({
      query: ({ id }) => ({
        url: `users/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: (result, err) =>
        result?.data ? [{ type: TAG, id: LIST }] : [],
    }),
  }),
});

export const {
  useGetUserByIdQuery,
  useGetUsersQuery,
  useCreateUserMutation,
  useReinviteUserMutation,
  useUpdateUserMutation,
  useDeleteUserMutation,
} = usersApi;
