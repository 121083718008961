import Cookies, {CookieAttributes} from "js-cookie";

export type CookieKey = 'reauth_count' | 'accessToken';
export const setCookie = (key: CookieKey, value: any, options?: CookieAttributes): string | undefined => {
    return Cookies.set(key, value, options);
};

export const removeCookie = (key: CookieKey, options?: CookieAttributes): void => {
    return Cookies.remove(key,  options);
};

export const getCookie = (key: CookieKey): string | undefined => {
    return Cookies.get(key);
};