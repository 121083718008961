import Tour from "shepherd.js/src/types/tour";

export const CANCEL_BUTTON = (args: {
  tour: Tour;
  text?: string;
  hook?: () => void;
  when?: object;
}) => ({
  classes: "shepherd-button-secondary",
  text: args?.text || "Exit",
  action: () => {
    if (args?.hook) args.hook();
    args.tour.cancel();
  },
  when: args?.when,
});

export const BACK_BUTTON = (args: {
  tour: Tour;
  text?: string;
  hook?: () => void;
  when?: object;
}) => ({
  classes: "shepherd-button-secondary",
  text: args?.text || "Back",
  action: () => {
    if (args?.hook) args.hook();
    args.tour.back();
  },
  when: args?.when,
});

export const NEXT_BUTTON = (args: {
  tour: Tour;
  text?: string;
  hook?: () => void;
  when?: object;
}) => ({
  classes: "shepherd-button-primary",
  text: args?.text || "Next",
  action: () => {
    if (args?.hook) args.hook();
    args.tour.next();
  },
  when: args?.when,
});
