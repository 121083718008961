import type { FC } from "react";
import { Link as RouterLink } from "react-router-dom";
import { Box } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import {useGetUserByIdQuery} from "../../store/services/users";
import useAuth from "../../hooks/useAuth";

const DashboardButton = () => {
  const {userId} = useAuth();
  const { data: userData, isLoading } = useGetUserByIdQuery({
    id: userId || '',
    include: ["permissions"],
  });
  
  return (
      <LoadingButton
        loading={isLoading}
        variant="contained"
        color="secondary"
        component={RouterLink}
        to={`/organizations/${userData?.data?.orgId}`}
        size="small"
      >
        Dashboard
      </LoadingButton>
  )
}

const LoginButton: FC = (props) => {
  const { isAuthenticated } = useAuth();
  
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
      }}
    >
      {isAuthenticated ? (
        <DashboardButton />
      ) : (
        <LoadingButton
          loading={false}
          to={"/authenticate"}
          variant="contained"
          color="secondary"
          component={RouterLink}
          size="small"
        >
          Login
        </LoadingButton>
      )}
    </Box>
  );
};

export default LoginButton;
