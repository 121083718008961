import Shepherd from "shepherd.js";
import Tour from "shepherd.js/src/types/tour";
import { getLocalStorage, setLocalStorage } from "../../../lib/localStorage";

const tourOptions = {
  useModalOverlay: true,
  defaultStepOptions: {
    cancelIcon: {
      enabled: true,
    },
  },
};

export function getStepsCounter(tour: Tour) {
  let currentStep = tour.getCurrentStep();
  if (currentStep) {
    return `${tour.steps.indexOf(currentStep) + 1}/${tour.steps.length}`;
  } else {
    return ``;
  }
}

export function createShepherdTour({ tourName }: { tourName: string }) {
  let tour = new Shepherd.Tour({ ...tourOptions, tourName });
  return tour;
}

export function saveTourConfig(args: {
  tourConfig: object;
  tourKey: string;
  stepId: string;
}) {
  setLocalStorage({
    key: "tour",
    value: JSON.stringify({
      ...args.tourConfig,
      [args.tourKey]: args.stepId,
    }),
  });
}

export function getTourConfig() {
  let localStorageTour = getLocalStorage({ key: "tour" });
  let tourConfig: { [k: string]: string | undefined } = localStorageTour
    ? JSON.parse(localStorageTour)
    : {};
  return tourConfig;
}
