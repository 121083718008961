import { createApi } from "@reduxjs/toolkit/query/react";

import { serverConfig } from "../../config";
import { ApiResponse } from "../../types/response";
import { axiosBaseQuery } from "./fetch";
import {GetJFrogResponseDto} from "@illumix-inc/types.apollo-monorepo";

const TAG = "SDK";

export const SDKApi = createApi({
  reducerPath: "SDKApi",
  baseQuery: axiosBaseQuery({
    baseUrl: `${serverConfig.url}/${serverConfig.endpoint_prefix}/${serverConfig.version}/`,
  }),
  tagTypes: [TAG],
  endpoints: (builder) => ({
    getSDKCredentials: builder.query<ApiResponse<GetJFrogResponseDto>, { id: string }>(
      {
        query: ({ id }) => ({
          url: `jfrog/${id}`,
          method: "GET",
        }),
        providesTags: (result, err) =>
          result?.data ? [{ type: TAG }] : [],
      }
    ),

  }),
});

export const {
  useGetSDKCredentialsQuery,
} = SDKApi;
